import React, { useEffect } from 'react';

import { Button, Card, Icon, Space, Typography } from '@sravni/react-design-system';
import { Cross } from '@sravni/react-icons';
import { useStyles } from '@sravni/react-utils';

import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';
import { clickActionsMap, eventCategoryMap, eventLabelMap, showActionsMap } from '../../constants/index';
import { IRecentActionCard } from '../../types/recentActionCard';

import styles from './ActionCard.module.scss';

const { Text, Link } = Typography;

export interface ActionCardProps extends IRecentActionCard {
  className?: string;
  closable?: boolean;
  isPrimaryCard?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onClose?: (id: string) => void;
}

export const ActionCard: React.FC<ActionCardProps> = (props) => {
  const {
    className,
    link,
    hint,
    hintColor,
    description,
    icon,
    withButton,
    buttonVariant,
    buttonText,
    cardColor,
    actionId,
    closable = false,
    isPrimaryCard = false,
    product,
    onClose,
    ...rest
  } = props;
  const cx = useStyles(styles);

  const iconColor = cardColor;
  const iconBackground = iconColor === 'red' ? 'red' : undefined;
  const hintClassnames = cx('hint', {
    [`hint-color-${hintColor}`]: hintColor,
  });

  const handleClick = () => {
    pushEvent(
      makeMainGEvent({
        eventAction: clickActionsMap[product],
        eventCategory: eventCategoryMap[product],
        eventLabel: eventLabelMap[product],
      }),
    );
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.(actionId);
  };

  useEffect(() => {
    product &&
      pushEvent(
        makeMainGEvent({
          eventAction: showActionsMap[product],
          eventCategory: eventCategoryMap[product],
          eventLabel: eventLabelMap[product],
        }),
      );
  }, [product]);

  const makeCard = () => (
    <Card
      className={cx(className, 'card')}
      variant="secondary"
      color={cardColor}
      size={16}
      onClick={handleClick}
      {...rest}
    >
      <Space size={12} direction="vertical" justify="space-between">
        <Space size={12} align="center" wrap>
          <Icon size={24} color={iconColor} background={iconBackground} className={cx('icon')}>
            {icon}
          </Icon>
          <Space className={cx('textContainer')} align="center">
            <Space size={2} direction="vertical">
              <Text size={12} className={hintClassnames}>
                {hint}
              </Text>
              <Text size={14} className={cx('description')} strong>
                {description}
              </Text>
            </Space>
            {closable && !isPrimaryCard && (
              <Icon size={20} className={cx('crossIcon')} onClick={handleDelete}>
                <Cross />
              </Icon>
            )}
          </Space>
        </Space>
        {withButton &&
          (link ? (
            <Link href={link}>
              <Button size={36} variant={buttonVariant} className={cx('linkButton')}>
                {buttonText}
              </Button>
            </Link>
          ) : (
            <Button size={36} variant={buttonVariant} className={cx('linkButton')}>
              {buttonText}
            </Button>
          ))}
      </Space>
      {closable && isPrimaryCard && (
        <Icon size={20} className={cx('crossIcon', 'primary')} onClick={handleDelete}>
          <Cross />
        </Icon>
      )}
    </Card>
  );

  return link ? <Link href={link}>{makeCard()}</Link> : makeCard();
};
