import { Typography } from '@sravni/react-design-system';
import { BasicСhecksquare } from '@sravni/react-icons';

import { CardItem } from '../CardItem';
import { SecurityCard } from '../SecurityCard';

export const PersonalData = () => {
  return (
    <SecurityCard>
      <Typography.Heading level={5}>
        Ваши персональные данные <br />
        надежно защищены
      </Typography.Heading>
      <CardItem text="Мы проходим регулярный аудит информационной безопасности">
        <BasicСhecksquare color="var(--color-G100)" />
      </CardItem>
      <CardItem text="Все данные передаются в&nbsp;зашифрованном виде">
        <BasicСhecksquare color="var(--color-G100)" />
      </CardItem>
      <CardItem text="Включены в реестр операторов персональных данных Роскомнадзора">
        <BasicСhecksquare color="var(--color-G100)" />
      </CardItem>
    </SecurityCard>
  );
};
