import React, { useEffect } from 'react';

import { Typography, Layout } from '@sravni/react-design-system';
import { useBoolean, useIsMobile, useStyles } from '@sravni/react-utils';

import { LayoutWrapper } from '../../../LayoutWrapper';
import { useRecentActions } from '../../../RecentActionsBlock/hooks/useRecentActions';
import { CreditCard } from '../CreditCard';
import { CreditsRatingCard } from '../CreditsRatingCard';
import { DepositsCard } from '../DepositsCard';
import { KaskoCard } from '../KaskoCard';
import { LoansCard } from '../LoansCard';
import { MortgageCard } from '../MortgageCard';
import { MortgageInsuranceCard } from '../MortgageInsuranceCard';
import { OsagoCard } from '../OsagoCard';
import { RecentActionsCard } from '../RecentActionsCard';
import { ServiceMenuCard } from '../ServiceMenuCard';
import { SportInsuranceCard } from '../SportInsuranceCard';
import { VzrCard } from '../VzrCard';

import styles from './ProductCardsContainer.module.scss';

export const ProductCardsContainer = React.memo(() => {
  const { recentActions, getRecentActions } = useRecentActions();
  const { orders, calculations } = recentActions;
  const [withBanner, setWithBanner] = useBoolean(false);
  const isMobile = useIsMobile();
  const cx = useStyles(styles);

  const cardsContainerClassnames = cx('gridContainer', {
    withBanner: !isMobile && withBanner,
  });

  useEffect(() => {
    if (!!orders?.length || !!calculations?.length) {
      setWithBanner.on();
    } else {
      setWithBanner.off();
    }
  }, [orders, calculations, setWithBanner]);

  return (
    <>
      <LayoutWrapper topBorders={false} bottomBorders={false} whiteBackground suppressHydrationWarning>
        <Layout className={`${cx('titleWrapper')}`}>
          <Typography.Heading level={3} as={'h1'} className={cx('title')}>
            Помогаем сохранить деньги
          </Typography.Heading>
          {withBanner && isMobile && (
            <RecentActionsCard recentActions={recentActions} getRecentActions={getRecentActions} />
          )}
        </Layout>
        <Layout className={cx('barContainer')}>
          <div className={cx('bar')}>
            {withBanner && !isMobile && (
              <RecentActionsCard recentActions={recentActions} getRecentActions={getRecentActions} />
            )}
            <div className={cardsContainerClassnames}>
              <OsagoCard />
              <CreditCard />
              <KaskoCard />
              <LoansCard />
              <MortgageInsuranceCard />
              <CreditsRatingCard />
              <DepositsCard />
              <MortgageCard />
              <VzrCard />
              <SportInsuranceCard />
              <ServiceMenuCard />
            </div>
          </div>
        </Layout>
      </LayoutWrapper>
    </>
  );
});
