import React from 'react';

import { IProductData, TYPES } from '../../types/productBlock';
import { LinkCard } from '../LinkCard';

import styles from './OsagoCard.module.scss';

const item: IProductData = {
  key: TYPES.OSAGO,
  customStyle: {},
  title: <>{'ОСАГО'}</>,
  eventCategory: 'ОСАГО',
  eventAction: 'Переход из плиток',
  subtitle: 'Поможем сэкономить до\u00A05\u00A0500\u00A0₽',
  pageUrl: '/osago/',
  picture: [
    <source
      key={`source-product-${TYPES.OSAGO}-webp`}
      srcSet={require('./img.png')}
      media={`(min-width: ${styles.breakpointTablet})`}
      type="image/webp"
    />,
    <img key={`img-product-${TYPES.OSAGO}`} srcSet={require('./img_mob.png')} alt="osago" />,
  ],
};

export const OsagoCard = () => <LinkCard item={item} className={styles.osago} />;
