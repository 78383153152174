import classNames from 'classnames';
import React from 'react';

import styles from './preloader.module.scss';

export enum TYPES {
  LIGHT = 'light',
}
interface INewPreloaderProps {
  style?: {
    [key: string]: string;
  };
  type?: TYPES;
  className?: string;
}

export const PreloaderPolygon: React.FC<INewPreloaderProps> = ({ style, className, type }) => (
  <div
    className={classNames(
      styles['preloader-polygon'],
      type === TYPES.LIGHT ? styles['preloader-polygon--light'] : null,
      className,
    )}
    style={style}
  />
);

export const PreloaderCircle: React.FC<INewPreloaderProps> = ({ style, className }) => (
  <div className={classNames(styles['preloader-circle'], className)} style={style} />
);
