import React, { useMemo, useState } from 'react';

import { Banner } from '@sravni/react-advertisement';
import { useStyles } from '@sravni/react-utils';

import { useBanners } from '../../hooks/advertising';
import { TBanner } from '../../interfaces/advertising';
import { AB_NAME } from '../../pages/_app';
import commonStyles from '../../styles/common.module.scss';

import styles from './special-projects.module.scss';

export const SpecialProjects = () => {
  const banners = useBanners();
  const cx = useStyles(styles);
  const [wereBannersLoaded, setBannerLoaded] = useState<boolean[]>([]);

  const allBannersLoaded = useMemo(() => {
    return wereBannersLoaded.every(Boolean) && wereBannersLoaded.length === 4;
  }, [wereBannersLoaded]);

  const teasers = useMemo(() => banners?.teaserInPropositions, [banners]);

  const renderTeaser = (teaser: TBanner, i) => {
    const handleRender = () => {
      setBannerLoaded((prevState) => {
        let newState = [...prevState];
        newState[i] = true;
        return newState;
      });
    };

    return (
      <div className={cx('item')} key={`${teaser.bannerId}-${i}`}>
        <Banner abName={AB_NAME} banner={teaser} onRender={handleRender} />
      </div>
    );
  };

  if (!teasers) return null;

  return (
    <div className={commonStyles.wrapper} style={{ height: allBannersLoaded ? 'auto' : '0px', marginBottom: '28px' }}>
      <div className={commonStyles.inner}>
        <div className={cx('carousel', 'h-mt-24')}>
          <div className={cx('carousel__content')}>{teasers?.map((teaser, i) => renderTeaser(teaser, i))}</div>
        </div>
      </div>
    </div>
  );
};
