import { Badge, Button, Card, Space, Typography } from '@sravni/react-design-system';

import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';

import styles from './MortgageCard.module.scss';

export const MortgageCard = () => {
  const handlePropositionLinkClick = () => {
    pushEvent(
      makeMainGEvent({
        eventCategory: 'Страхование ипотеки',
        eventAction: 'Рассчет СИ',
        eventLabel: 'Главная страница',
      }),
    );
  };

  return (
    <Typography.Link href="/strahovanie-ipoteki/" onClick={handlePropositionLinkClick} className={styles.link}>
      <Card size={16} className={styles.card}>
        <Badge text="экономия до 40%" variant="secondary" color="green" className={styles.badge} />
        <Space direction="column" justify="space-between">
          <Space size={4} direction="column">
            <Typography.Heading level={5} className="h-color-D100">
              Застрахуйте <br />
              свою ипотеку
            </Typography.Heading>
            <Typography.Text className="h-color-D60">В аккредитованных компаниях</Typography.Text>
          </Space>
          <Button variant="secondary" color="green" size={36}>
            Рассчитать
          </Button>
        </Space>
        <img src={require('./img.png')} className={styles.img} alt="rate" />
      </Card>
    </Typography.Link>
  );
};
