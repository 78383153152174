import { useCallback, MouseEvent } from 'react';

import { Card as DsCard } from '@sravni/react-design-system';
import type { CardProps as DsCardProps } from '@sravni/react-design-system/lib/Card';
import { useStyles } from '@sravni/react-utils';

import { makeMainGEvent, pushEvent } from '../../../../utils/analytics';
import { IProductData } from '../../types/productBlock';

import styles from './Card.module.scss';

export type CardProps = DsCardProps & {
  item: IProductData;
};

export const Card = (props: CardProps) => {
  const { item, onClick, className, children, ...rest } = props;
  const cx = useStyles(styles);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const { eventAction, eventCategory, eventLabel } = item;

      pushEvent(
        makeMainGEvent({
          eventAction,
          eventCategory,
          eventLabel,
          eventValue: undefined,
        }),
      );
      onClick?.(event);
    },
    [item, onClick],
  );

  return (
    <DsCard size={16} className={cx(className, 'card')} onClick={handleClick} color="dark" {...rest}>
      {children}
    </DsCard>
  );
};
