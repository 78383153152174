import { compareDesc } from 'date-fns/compareDesc';
import { parseISO } from 'date-fns/parseISO';

import { IAggregatedRate } from '../interfaces/currencies';

export function decorateCurrencies(curRates, oilRates) {
  const result =
    curRates &&
    curRates.reduce((output, item) => {
      const itemKey = item && item.currency && item.currency.isoCode;

      if (itemKey) {
        if (!output[itemKey]) {
          output[itemKey] = [];
        }

        if (output[itemKey]) {
          if (output[itemKey].length < 2) {
            output[itemKey].push(item);
          }

          if (output[itemKey].length === 2) {
            const result = {
              dateFrom: {},
              dateTo: {},
            };

            [result.dateFrom, result.dateTo] = output[itemKey].sort((a, b) =>
              compareDesc(parseISO(b.updateDate), parseISO(a.updateDate)),
            );

            output[itemKey] = result;
          }
        }
      }

      return output;
    }, {});

  if (oilRates && oilRates.items) {
    const oil = oilRates.items.length > 2 ? oilRates.items.slice(0, 1) : oilRates.items;
    const oilRes = {
      dateFrom: {},
      dateTo: {},
    };

    [oilRes.dateFrom, oilRes.dateTo] = oil.sort((a, b) => compareDesc(parseISO(b.date), parseISO(a.date)));

    result.OIL = oilRes;
  }

  return result;
}

export const getBankCurrencies = (bankRates: IAggregatedRate[]): Record<string, IAggregatedRate> => {
  if (!bankRates) {
    return;
  }

  return bankRates.reduce((output: Record<string, IAggregatedRate>, rate) => {
    const itemKey = rate && rate.currency && rate.currency.isoCode;

    if (itemKey) {
      output[itemKey] = rate;
    }

    return output;
  }, {});
};
