import { format, formatDistanceToNowStrict, isToday } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { useCallback, useMemo } from 'react';

import { generateNewsLink } from '../../helpers/generateNewsLink';
import { IMaterialItem, IRubricItem } from '../../interfaces/news';
import { makeAdGEvent, pushEvent } from '../../utils/analytics';
import { Card, TCompressions } from '../ui/Card';

interface IMaterialItemProps {
  item: IMaterialItem;
  dictionary: IRubricItem[];
}

const formatDate = (data: Date): string => {
  if (isToday(data)) {
    return formatDistanceToNowStrict(data, { addSuffix: true, locale: ru });
  }

  return format(data, 'd MMMM', { locale: ru });
};

const MaterialItem: React.FC<IMaterialItemProps> = React.memo(({ item, dictionary }) => {
  const handleGAEvent = useCallback(() => {
    pushEvent(
      makeAdGEvent({
        eventCategory: 'Контент',
        eventAction: 'Переход из превью',
        eventLabel: `https://www.sravni.ru/text/${item.alias}`,
      }),
    );
  }, [item]);

  const cardData = useMemo(() => {
    const date = new Date(item.publishDate);
    const tags = item.tags.filter((tag) => tag === 'Партнёрский материал');
    const rubricName = dictionary?.find((el) => el.alias === item.rubric)?.name || '';
    const link = item.type === 'news' ? generateNewsLink(item) : `/text/${item.alias}`;

    return {
      imgSrc: item.miniPicture?.url ?? item.picture?.url,
      imgCompressions: (window.devicePixelRatio >= 2 ? ['l', 'xl'] : ['m', 'l']) as [TCompressions, TCompressions],
      link: item.externalLink || link,
      tags,
      rubricAlias: item.rubric,
      rubricName,
      title: { __html: item.title },
      viewsCount: item.visits,
      likesCount: item.likes,
      commentsCount: item.commentsCount,
      date: formatDate(date),
      priorityOnMain: item.priorityOnMain,
      handleClick: handleGAEvent,
    };
  }, [item, handleGAEvent, dictionary]);

  return <Card item={cardData} />;
});

export default MaterialItem;
