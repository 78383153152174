import getConfig from 'next/config';

import {
  IDictionaryResponse,
  IMaterialItem,
  IMaterialResponse,
  IMaterialsTopsResponse,
  IRubricsResponse,
} from '../interfaces/news';
import client from '../utils/client';

interface IDictionaryParams {
  type: string;
}

interface INewsParams {
  page: number;
  limit: number;
}

interface IRubricsParams {
  placement?: string;
}

interface IMaterialsParams {
  limit?: number;
  placement?: string;
  placementLimit?: number;
}

export const getDictionary = async (params: IDictionaryParams): Promise<IDictionaryResponse> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/news/dictionary/`;
  const { data } = await client.get<IDictionaryResponse>(url, { params });

  return data;
};

export const getRubrics = async (params?: IRubricsParams): Promise<IRubricsResponse> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/news/rubrics/`;
  const { data } = await client.get<IRubricsResponse>(url, { params });

  return data;
};

export const getMaterialsPopular = async (params: IMaterialsParams): Promise<IMaterialItem[]> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/news/materials/popular`;
  const { data } = await client.get<IMaterialItem[]>(url, { params });

  return data;
};

export const getHighlightsTops = async (): Promise<IMaterialsTopsResponse> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/news/materials/highlights/tops`;
  const { data } = await client.get<IMaterialsTopsResponse>(url);

  return data;
};

export const getHighlightsNews = async (params: INewsParams): Promise<IMaterialResponse> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/news/materials/highlights/news`;
  const { data } = await client.get<IMaterialResponse>(url, { params });

  return data;
};
