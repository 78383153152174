import React from 'react';

import { Typography } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';

import { IProductData } from '../../types/productBlock';
import { Card } from '../Card';
import { CardContent } from '../CardContent';

import styles from './LinkCard.module.scss';

export type LinkCardProps = {
  item: IProductData;
  className?: string;
};

export const LinkCard = (props: LinkCardProps) => {
  const { item, className } = props;
  const cx = useStyles(styles);

  return (
    <Typography.Link color="dark" href={item.pageUrl} className={cx(className, 'link')}>
      <Card item={item}>
        <CardContent item={item} />
      </Card>
    </Typography.Link>
  );
};
