import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Typography, Grid } from '@sravni/react-design-system';
import { ColSpanAdaptive } from '@sravni/react-design-system/dist/types/components/Grid/types/Col.types';
import { useIsMobile } from '@sravni/react-utils';

import { BANNERS_DATA_NEW, IBlock } from '../../constants/promoCarouse';
import commonStyles from '../../styles/common.module.scss';
import { makeMainGEvent, pushEvent } from '../../utils/analytics';

import { Card } from './components/Card';
import styles from './promo-block.module.scss';

export const PromoBlock = React.memo<{ darkTheme: boolean }>(({ darkTheme }) => {
  const [originUrl, setOriginUrl] = useState<string>('');
  const isMobile = useIsMobile();

  useEffect(() => {
    setOriginUrl(document.location.origin);
  }, []);

  const handleClick = useCallback(
    ({ partnerPageUrl, pageUrl, eventAction, eventCategory, eventLabel }: IBlock) => {
      const link = partnerPageUrl ? partnerPageUrl : `${originUrl}${pageUrl}`;

      try {
        pushEvent(
          makeMainGEvent({
            eventAction: eventAction ?? 'Переход из плиток',
            eventCategory,
            eventLabel,
            eventValue: undefined,
          }),
        );
      } catch (error) {
        console.error(error);
      }

      document.location.href = link;
    },
    [originUrl],
  );

  const spanAdaptive = React.useMemo<ColSpanAdaptive>(() => ({ mobile: 12, tablet: 6, desktop: 4 }), []);

  const columns = useMemo(() => {
    return Object.values(BANNERS_DATA_NEW).map((innerColumns, index) => (
      <Grid.Col key={`columns-${index}`} className={styles.columns} span={spanAdaptive}>
        {innerColumns.rows.map((rows, indexRows) => (
          <Grid.Row key={`rows-${indexRows}`} gutter={isMobile ? [8, 8] : [16, 16]} wrap={false}>
            {rows.row.map((block) => (
              <Grid.Col key={block.key} className={styles.column} style={block.customStyle}>
                <Card item={block} handleClick={handleClick} darkTheme={darkTheme} isMobile={isMobile} />
              </Grid.Col>
            ))}
          </Grid.Row>
        ))}
      </Grid.Col>
    ));
  }, [darkTheme, handleClick, isMobile, spanAdaptive]);

  return (
    <div className={`${commonStyles.wrapper} ${commonStyles.whiteBackground}`} suppressHydrationWarning>
      <div className={classNames(commonStyles.inner, styles.beforeHeader)}>
        <Typography.Heading level={3} as={'h1'}>
          Помогаем сохранить деньги
        </Typography.Heading>
        <div className={styles.contentNew}>
          <Grid.Row gutter={[isMobile ? 8 : 16, 0]}>{columns}</Grid.Row>
        </div>
      </div>
    </div>
  );
});
