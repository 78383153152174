import { IUserAction } from '../../../interfaces/recentAction';

export enum CREDIT_ACTION_STATUS {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export enum CREDIT_ACTION_EXTRNAL_STATUS {
  APPROVED = 'approved',
  FINAL_APPROVED = 'finalapproved',
  CONFIRMED = 'confirmed',
}

export enum MORTGAGE_INSURANCE_TYPE {
  LIFE = 'life',
  'PROPERTY-LIFE' = 'property-life',
  PROPERTY = 'property',
}

export interface IMortgageCalculationData extends Omit<IUserAction, 'meta'> {
  meta: {
    searchId: string;
    filters?: {
      type: `${MORTGAGE_INSURANCE_TYPE}`;
    };
  };
}

export interface IMortgageOrderData extends Omit<IUserAction, 'meta' | 'payload'> {
  meta: {
    externalId: string;
  };
  payload: {
    dateEnd: string;
    nextPolicyNumber?: string;
  };
}

export interface IOsagoCalculationData extends Omit<IUserAction, 'meta' | 'payload'> {
  meta: {
    searchId: string;
  };
  payload: {
    mark?: string;
    model?: string;
  };
}

export interface IOsagoOrderData extends Omit<IUserAction, 'meta' | 'payload'> {
  meta: {
    externalId: string;
  };
  payload: {
    dateEnd: string;
    nextPolicyNumber?: string;
    mark?: string;
    model?: string;
  };
}

export interface ICreditCalculationData extends Omit<IUserAction, 'meta' | 'payload'> {
  meta: {
    filters?: {
      amount: number;
    };
  };
  payload: {
    products?: string[];
    apiStatus?: CREDIT_ACTION_STATUS;
    externalRequestStatus?: CREDIT_ACTION_EXTRNAL_STATUS;
  };
}
