import { CardItemProps } from 'components/SecurityBlock/types/CardItem';
import { FC } from 'react';

import { Space, Icon, Typography } from '@sravni/react-design-system';

export const CardItem: FC<CardItemProps> = (props) => {
  const { text, children } = props;

  return (
    <Space size={16} align="center">
      <Icon>{children}</Icon>
      <Typography.Text>{text}</Typography.Text>
    </Space>
  );
};
