import { startOfDay, parseISO, format, formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';

import { Badge } from '@sravni/react-design-system';
import { Car, House, TimeDotted, DocumentEdit, Wallet } from '@sravni/react-icons';
import pluralize from '@sravni/utils/lib/pluralize';

import { ACTION_TYPE, IUserAction, PRODUCT_TYPE } from '../../../interfaces/recentAction';
import { mortgageInsuranceTypeMap } from '../constants';
import { CREDIT_ACTION_EXTRNAL_STATUS, CREDIT_ACTION_STATUS } from '../types/actionDataTypes';
import { IRecentActionCard, IRecentActions } from '../types/recentActionCard';

import { addThousandSpaces } from './changeData';
import {
  isMortgageCalculation,
  isMortgageOrder,
  isOsagoCalculation,
  isRelevantDate,
  isOsagoOrder,
  isCreditCalculation,
  isCreditOrder,
} from './validateData';

/** Helper function prepares recent action cards data */
export const prepareActionCards = (actions: IUserAction[]): IRecentActions => {
  const creditSelectionData = {
    index: null,
    products: null,
    approveCount: 0,
  };

  const data = actions.reduce<IRecentActions>(
    (acc, action) => {
      const defaultValue: IRecentActionCard = {
        actionId: action.actionId,
        cardColor: 'blue',
        icon: '',
        hint: '',
        hintColor: undefined,
        description: '',
        buttonVariant: 'outlined',
        buttonText: '',
        link: '',
        product: undefined,
      };

      switch (action.productType) {
        case PRODUCT_TYPE.MORTGAGE:
          // Расчет
          if (isMortgageCalculation(action)) {
            const { meta } = action;
            const mortgageType = meta.filters;

            defaultValue.icon = <House />;
            defaultValue.hint = mortgageType ? 'Полис для ипотеки' : 'Продолжить покупку';
            defaultValue.description = mortgageType ? mortgageInsuranceTypeMap[meta.filters.type] : 'Полис для ипотеки';
            defaultValue.buttonText = 'Продолжить';
            defaultValue.link = `/strahovanie-ipoteki/propositions/?searchId=${meta.searchId}`;
            defaultValue.product = `${PRODUCT_TYPE.MORTGAGE}-${ACTION_TYPE.SEARCH_RESULTS_V2}`;

            acc.calculations.push({ ...defaultValue });
            return acc;
          }
          // Полис
          if (isMortgageOrder(action) && isRelevantDate(action) && !action.payload.nextPolicyNumber) {
            const { meta, payload } = action;
            const dateEnd = parseISO(payload.dateEnd);

            defaultValue.cardColor = 'red';
            defaultValue.icon = <House />;
            defaultValue.hintColor = 'red';
            defaultValue.hint =
              dateEnd > startOfDay(new Date())
                ? `Заканчивается ${format(dateEnd, 'd MMMM', { locale: ru })}`
                : `Закончился ${formatDistanceToNow(dateEnd, { locale: ru })} назад`;
            defaultValue.description = 'Полис для ипотеки';
            defaultValue.buttonText = 'Продлить';
            defaultValue.buttonVariant = 'primary';
            defaultValue.link = `/strahovanie-ipoteki/propositions/${meta.externalId}/?&source_type=yourActions`;
            defaultValue.product = `${PRODUCT_TYPE.MORTGAGE}-${ACTION_TYPE.ORDER_V2}`;

            acc.orders.push({ ...defaultValue });
            return acc;
          }
          break;

        case PRODUCT_TYPE.OSAGO:
          // Расчет
          if (isOsagoCalculation(action)) {
            const { meta, payload } = action;
            defaultValue.icon = <Car />;
            defaultValue.hint = 'Полис ОСАГО';
            defaultValue.description = `${payload.mark || ''} ${payload.model || ''}`;
            defaultValue.buttonText = 'Вернуться';
            defaultValue.link = `/osago/?calculationHash=${meta.searchId}`;
            defaultValue.product = `${PRODUCT_TYPE.OSAGO}-${ACTION_TYPE.SEARCH_RESULTS_V2}`;

            acc.calculations.push({ ...defaultValue });
            return acc;
          }
          // Полис
          if (isOsagoOrder(action) && isRelevantDate(action) && !action.payload.nextPolicyNumber) {
            const { meta, payload } = action;
            const dateEnd = parseISO(payload.dateEnd);

            defaultValue.cardColor = 'red';
            defaultValue.icon = <Car />;
            defaultValue.hint =
              dateEnd > startOfDay(new Date())
                ? `Заканчивается ${format(dateEnd, 'd MMMM', { locale: ru })}`
                : `Закончился ${formatDistanceToNow(dateEnd, { locale: ru })} назад`;
            defaultValue.hintColor = 'red';
            defaultValue.description = `${payload.mark || ''} ${payload.model || ''}`;
            defaultValue.buttonVariant = 'primary';
            defaultValue.buttonText = 'Продлить';
            defaultValue.link = `/osago/?orderHash=${meta.externalId}`;
            defaultValue.product = `${PRODUCT_TYPE.OSAGO}-${ACTION_TYPE.ORDER_V2}`;

            acc.orders.push({ ...defaultValue });
            return acc;
          }
          break;

        case PRODUCT_TYPE.CREDIT:
          // Расчет
          if (isCreditCalculation(action)) {
            const { meta, payload } = action;
            const {
              filters: { amount },
            } = meta;
            const amountString = amount ? `на ${addThousandSpaces(amount)} ₽` : '';
            const products = payload.products?.length;
            creditSelectionData.products = products;
            creditSelectionData.index = acc.calculations.length;

            defaultValue.icon = <Wallet />;
            defaultValue.hint = 'Продолжить заполнение';
            defaultValue.hintColor = products ? 'blue' : undefined;
            defaultValue.description = `Кредит ${amountString}`;
            defaultValue.buttonText = 'Продолжить';
            defaultValue.link = `/kredity/onlajn-zayavka-na-kredit/`;
            defaultValue.product = `${PRODUCT_TYPE.CREDIT}-${ACTION_TYPE.SEARCH_RESULTS_V2}`;

            acc.calculations.push({ ...defaultValue });
            return acc;
          }

          // Заявка
          if (isCreditOrder(action)) {
            const { payload } = action;
            if (
              payload.apiStatus === CREDIT_ACTION_STATUS.ACCEPTED &&
              [
                CREDIT_ACTION_EXTRNAL_STATUS.APPROVED,
                CREDIT_ACTION_EXTRNAL_STATUS.FINAL_APPROVED,
                CREDIT_ACTION_EXTRNAL_STATUS.CONFIRMED,
              ].includes(payload.externalRequestStatus)
            ) {
              creditSelectionData.approveCount = creditSelectionData.approveCount + 1;
            }
          }

          break;

        // ВЗР на текущий момент не добавляем
        // case PRODUCT_TYPE.VZR:
        //   // Расчет
        //   if (actionType === ACTION_TYPE.SEARCH_RESULTS_V2) {
        //     defaultValue.icon = <Plane />;
        //     defaultValue.hint = 'Продолжить заполнение';
        //     defaultValue.description = `Страхование НС`;
        //     defaultValue.buttonText = 'Продолжить';
        //     defaultValue.link = `${meta?.url}`;

        //     acc.calculations.push({ ...defaultValue });
        //     return acc;
        //   }

        //   break;
      }

      return acc;
    },
    { orders: [], calculations: [], primaryAction: {} },
  );

  if (creditSelectionData.index) {
    const { index, products, approveCount } = creditSelectionData;
    const hintString = [
      products ? `${products} ${pluralize(products, 'предложение', 'предложения', 'предложений')}` : '',
      approveCount ? `, ${approveCount} одобрено` : '',
    ].join('');
    const hint = hintString ?? 'Продолжить заполнение';

    data.calculations[index].hint = hint;
    data.calculations[index].product = `${PRODUCT_TYPE.CREDIT}-${ACTION_TYPE.ORDER_V2}`;
  }

  const ordersLength = data.orders.length;
  const calculationsLength = data.calculations.length;
  if (ordersLength + calculationsLength <= 1) {
    data.primaryAction = data.orders[0] || data.calculations[0];
  } else {
    data.primaryAction = !!ordersLength
      ? {
          cardColor: 'red',
          icon: <TimeDotted />,
          hint: 'Расчеты и заявки',
          hintColor: 'red',
          description: 'У вас есть полисы к продлению',
          buttonText: (
            <>
              Посмотреть <Badge text={ordersLength + calculationsLength} shape="circle" color="red" variant="primary" />
            </>
          ),
          buttonVariant: 'primary',
          link: '',
        }
      : {
          cardColor: 'blue',
          icon: <DocumentEdit />,
          hint: 'Вернуться к расчетам',
          hintColor: undefined,
          description: 'Сохранили ваши расчеты и заявки',
          buttonText: (
            <>
              Показать <Badge text={ordersLength + calculationsLength} shape="circle" color="red" variant="primary" />
            </>
          ),
          buttonVariant: 'outlined',
          link: '',
        };
  }

  return data;
};
