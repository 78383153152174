import React from 'react';

import { useStyles } from '@sravni/react-utils';

import styles from './LayoutWrapper.module.scss';

export interface LayoutWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  topBorders?: boolean;
  bottomBorders?: boolean;
  whiteBackground?: boolean;
}

export const LayoutWrapper = React.forwardRef<HTMLDivElement, LayoutWrapperProps>((props, ref) => {
  const { className, children, topBorders = true, bottomBorders = true, whiteBackground = false, ...restProps } = props;

  const cx = useStyles(styles);
  const containerClassNames = cx(className, 'container', {
    whiteBackground,
  });
  const auxillaryLayerClassNames = cx('auxillaryLayer', {
    topBorders,
    bottomBorders,
    whiteBackground,
  });

  return (
    <div ref={ref} className={containerClassNames} {...restProps}>
      <div className={auxillaryLayerClassNames}>{children}</div>
    </div>
  );
});
