import getConfig from 'next/config';

import { IAggregatedRate, IRate } from '../interfaces/currencies';
import client from '../utils/client';

interface ICBRatesProps {
  dateFrom?: any;
  dateTo?: any;
  lastTakeCount?: number;
  isoCodes?: string[];
}

export const getCbrfRates = async (params: ICBRatesProps): Promise<IRate[]> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/currencies/cbrfRates/`;
  const { data } = await client.get<IRate[]>(url, { params });

  return data;
};

export const getBankRates = async (): Promise<IAggregatedRate[]> => {
  const url = `${getConfig().publicRuntimeConfig.apiPath}/currencies/bankRates`;
  const { data } = await client.get<IAggregatedRate[]>(url);

  return data;
};
