import { getDate } from 'date-fns/getDate';
import { getMonth } from 'date-fns/getMonth';
import { getYear } from 'date-fns/getYear';

import { IMaterialItem } from '../interfaces/news';

export const getDateForUrl = (publishDate: string) => {
  const date = new Date(publishDate);

  return {
    year: getYear(date),
    month: getMonth(date) + 1,
    day: getDate(date),
  };
};

export const generateNewsLink = (item: IMaterialItem): string => {
  const { publishDate, alias } = item;
  const { year, month, day } = getDateForUrl(publishDate);

  return `/novost/${year}/${month}/${day}/${alias}`;
};
