import { Card, Space, Typography } from '@sravni/react-design-system';

import { SecurityCard } from '../SecurityCard';

import styles from './CustomerCare.module.scss';

export const CustomerCare = () => {
  return (
    <SecurityCard>
      <Space direction="column" size={16}>
        <Typography.Heading level={5}>
          Служба заботы <br />о клиентах
        </Typography.Heading>
        <Typography.Text>
          Если к вам обратились от имени Сравни с просьбой предоставить персональные данные, обратитесь в службу
          поддержки по официальному номеру.
        </Typography.Text>
      </Space>
      <Card size={16} className="h-p-12">
        <Space direction="column">
          <Typography.Text>Ежедневно с 9:00 до 21:00 по Москве</Typography.Text>
          <Typography.Heading level={5} className={styles.phone}>
            8 800 707-39-02
          </Typography.Heading>
        </Space>
      </Card>
    </SecurityCard>
  );
};
