import cn from 'classnames';
import { memo } from 'react';

import { Icon, Space, Typography } from '@sravni/react-design-system';
import { Briefcase, Calculations, CardTerminal, Deposit, House, Orders, Shield } from '@sravni/react-icons';
import { useIsMobile } from '@sravni/react-utils';

import { IRubricItem } from '../../interfaces/news';

import styles from './materials.module.scss';

const icons = {
  all: <Calculations />,
  vklady: <Deposit />,
  karty: <CardTerminal />,
  kredity: <Orders />,
  investicii: <Briefcase />,
  nedvizhimost: <House />,
  strahovki: <Shield />,
};

export const Tabs = memo(({ rubrics }: { rubrics: IRubricItem[] }) => {
  const isMobile = useIsMobile();

  if (!rubrics?.length) return null;

  return (
    <div className={styles.tabsOuter}>
      <div className={isMobile && styles.tabsWrapper}>
        <Space size={20} className={isMobile ? 'h-mt-16' : 'h-mt-24'}>
          {rubrics.slice(0, 6).map((item) => {
            const isMain = item.alias === 'all';

            return (
              <a
                key={`rubric-${item.alias}`}
                className={cn(styles.tabLink)}
                href={isMain ? '/novosti/' : `/novosti/rubrika/${item.alias}/`}
              >
                <Icon size={20} background="light" shape="round">
                  {icons[item.alias]}
                </Icon>
                <Typography.Text size={16} strong>
                  {isMain ? 'Главное' : item.name}
                </Typography.Text>
              </a>
            );
          })}
        </Space>
      </div>
    </div>
  );
});
