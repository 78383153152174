import React from 'react';

import { Icon, Space, Typography } from '@sravni/react-design-system';
import { useIsMobile, useIsPhone, useStyles } from '@sravni/react-utils';

import { IProductData } from '../../types/productBlock';
import { makeAttribute } from '../../utils/attribute';

import styles from './CardContent.module.scss';

export type CardContentProps = {
  item: IProductData;
};

export const CardContent = (props: CardContentProps) => {
  const { item } = props;

  const cx = useStyles(styles);
  const isMobile = useIsMobile();
  const isPhone = useIsPhone();

  const pictureClassNames = cx('pictureContainer', {
    [item.key]: item.key,
  });

  return (
    <>
      <Space direction="vertical" justify="space-between">
        <Space direction="vertical" size={2}>
          {!!makeAttribute(item.icon, isMobile) && <Icon icon={makeAttribute(item.icon, isMobile)} />}
          <Space direction="vertical" size={4}>
            <Space direction="vertical" size={2}>
              {item.title && (
                <Typography.Heading level={6} className={cx('cardTitle')}>
                  {item.title}
                </Typography.Heading>
              )}
              {makeAttribute(item.subtitle, isMobile) && (
                <Typography.Text size={10} className={cx('cardSubtitle')}>
                  {makeAttribute(item.subtitle, isMobile)}
                </Typography.Text>
              )}
            </Space>
            {item.badge && <div className={styles.badge}>{item.badge}</div>}
          </Space>
        </Space>
        {!!makeAttribute(item.bottomIcon, isPhone) && <Icon icon={makeAttribute(item.bottomIcon, isPhone)} />}
      </Space>
      {item.picture && (
        <div className={pictureClassNames}>
          <picture>{item.picture}</picture>
        </div>
      )}
    </>
  );
};
