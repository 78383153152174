import React, { memo, useCallback, useMemo } from 'react';

import { Card as DsCard, Space, Typography, Icon } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';

import { IBlock } from '../../../../constants/promoCarouse';

import styles from './Card.module.scss';

export type IBlockProps = {
  item: IBlock;
  handleClick: (block: IBlock) => void;
  darkTheme: boolean;
  isMobile?: boolean;
};

export const Card = memo<IBlockProps>(({ item, handleClick, darkTheme, isMobile }) => {
  const cx = useStyles(styles);
  const textWrapperClassNames = cx({
    textWrapper: item.picture,
  });

  const pictureClassNames = cx('pictureContainer', {
    [item.key]: item.key,
  });

  const innerPicture = useMemo(
    () => (darkTheme && item.pictureForDarkTheme ? item.pictureForDarkTheme : item.picture),
    [darkTheme, item.picture, item.pictureForDarkTheme],
  );

  const handleCardClick = useCallback(() => {
    handleClick(item);
  }, [item, handleClick]);

  const badge = useMemo(() => {
    if (item.mobileBadge && isMobile) {
      return item.mobileBadge;
    }

    return item.badge;
  }, [item, isMobile]);

  return (
    <DsCard size={16} className={styles.card} onClick={handleCardClick} color="dark">
      <div className={styles.cardContainer}>
        <Space direction="vertical" justify="space-between">
          <Space className={textWrapperClassNames} direction="vertical" size={4}>
            {item.title && (
              <Typography.Heading level={6} className={styles.cardTitle}>
                {item.title}
              </Typography.Heading>
            )}
            {item.subtitle && (
              <Typography.Text size={12} className={styles.cardSubtitle}>
                {item.subtitle}
              </Typography.Text>
            )}
          </Space>
          <Space className={styles.iconWrapper} justify={item.badge ? 'space-between' : 'end'} align="center">
            {badge}
            {item.icon && <Icon icon={item.icon} size={isMobile ? 24 : 28} />}
          </Space>
        </Space>
        {innerPicture && (
          <div className={pictureClassNames}>
            <picture>{innerPicture}</picture>
          </div>
        )}
      </div>
    </DsCard>
  );
});
