import cn from 'classnames';
import React, { useState } from 'react';

import { Typography } from '@sravni/react-design-system';
import { SpecialOffers } from '@sravni/react-ugc/lib/SpecialOffers';
import { useAnalytics, useBoolean, useIsMobile } from '@sravni/react-utils';
import { makeTab } from '@sravni/ugc/lib/specialOffers/helpers';
import { ACTIONS } from '@sravni/ugc/lib/specialOffers/lists';

import { AB_NAME } from '../../pages/_app';
import commonStyles from '../../styles/common.module.scss';

const RecentActions = React.memo(({ isShowBVersion }: { isShowBVersion?: boolean }) => {
  const isMobile = useIsMobile();
  const [itemsCount, setItemsCount] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useBoolean();
  const { pushEvent } = useAnalytics();

  if (!isMobile && itemsCount && itemsCount < 3) return null;

  return (
    <>
      <div
        className={cn({ [commonStyles.wrapper]: isLoaded })}
        style={{ height: isLoaded && itemsCount > 0 ? 'auto' : '0px', overflow: 'hidden' }}
      >
        <div className={cn({ [commonStyles.inner]: isLoaded })}>
          <SpecialOffers
            heading={<Typography.Heading level={2} />}
            headingType={'horizontal'}
            pushEvent={pushEvent}
            isBVersion={isShowBVersion}
            abName={AB_NAME}
            onLoad={setIsLoaded.on}
            setItemsCount={setItemsCount}
            tabs={[makeTab(ACTIONS.osago)]}
          />
        </div>
      </div>
    </>
  );
});

export default RecentActions;
