import React from 'react';

import { Badge, Card, Icon, Space, Typography } from '@sravni/react-design-system';
import { TravelVacation } from '@sravni/react-icons';
import { useStyles } from '@sravni/react-utils';

import { makeMainGEvent, pushEvent } from '../../utils/analytics';

import { travelLinks } from './constants/travelLinks';
import styles from './Travel.module.scss';

const { Link, Heading } = Typography;

export const Travel = () => {
  const cx = useStyles(styles);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    const label = (event.target as HTMLAnchorElement).getAttribute('data-eventlabel');

    pushEvent(
      makeMainGEvent({
        eventCategory: 'Главная',
        eventAction: 'Баннер по путешествиям',
        eventLabel: `Главная|${label}`,
      }),
    );
  }, []);

  return (
    <Card size={16} color="blue" className={cx('card')}>
      <Space align="center" justify="space-between" className={cx('wrapper')}>
        <Icon size={20} background="light" shape="round">
          {<TravelVacation />}
        </Icon>
        <Space size={4} justify="space-between" align="center" wrap={true}>
          <Space align="center" size={4} className={cx('titleWrapper', 'h-pr-12')}>
            <Heading level={6}>Путешествия</Heading>
            <Badge text={'Новое'} color="red" variant="primary" />
          </Space>
          <Space className={cx('wrapper', 'linksWrapper')} wrap={true}>
            {travelLinks.map(({ title, href, eventLabel }) => (
              <Link key={title} data-eventlabel={eventLabel} href={href} className={cx('link')} onClick={handleClick}>
                {title}
              </Link>
            ))}
          </Space>
        </Space>
      </Space>
    </Card>
  );
};
