import { generateDepositHref } from '../../../utils/deposits';
import { IPropositions } from '../types/propositionBlock';

export enum PROPOSITION_TYPE {
  DEPOSITS = 'Вклады',
  MORTGAGE = 'Ипотека',
}

export const PROPOSITION_LINK = {
  DEPOSITS: {
    href: generateDepositHref(),
    title: 'Все вклады',
  },
  MORTGAGE: {
    href: '/ipoteka/',
    title: 'Подберите лучшее предложение',
    eventAction: 'Платный клик',
    eventLabel: 'Онлайн-заявка на ипотеку|Подберите лучшее предложение|Переход из плиток с деталями',
  },
};

export const MORTGAGE_DATA: IPropositions = [
  { title: 'Семейная', rateTitle: 'от 5,5%', href: '/ipoteka/lgotnaya-gospodderzhkoy/' },
  { title: 'Строительство дома', rateTitle: 'от 4,9%', href: '/ipoteka/na-stroitelstvo-doma/' },
  { title: 'IT-ипотека', rateTitle: 'от 4,7%', href: '/ipoteka/it-ipoteka/' },
  { title: 'Вторичка', rateTitle: 'от 16,7%', href: '/ipoteka/zhile-na-vtorichnom-rynke/' },
];
