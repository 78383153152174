import { ACTION_TYPE, PRODUCT_TYPE, ProductActionType } from '../../../interfaces/recentAction';
import { MORTGAGE_INSURANCE_TYPE } from '../types/actionDataTypes';

export const mortgageInsuranceTypeMap = {
  [MORTGAGE_INSURANCE_TYPE.LIFE]: 'Жизнь',
  [MORTGAGE_INSURANCE_TYPE['PROPERTY-LIFE']]: 'Жизнь + Квартира',
  [MORTGAGE_INSURANCE_TYPE.PROPERTY]: 'Квартира',
};

export const clickActionsMap: Partial<Record<ProductActionType, string>> = {
  [`${PRODUCT_TYPE.OSAGO}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Клик|Продолжить расчёт',
  [`${PRODUCT_TYPE.OSAGO}-${[ACTION_TYPE.ORDER_V2]}`]: 'Клик|Продлить полис',
  [`${PRODUCT_TYPE.MORTGAGE}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Клик|Продолжить расчёт',
  [`${PRODUCT_TYPE.MORTGAGE}-${[ACTION_TYPE.ORDER_V2]}`]: 'Клик|Продлить полис',
  [`${PRODUCT_TYPE.CREDIT}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Переход из баннера возврата',
  [`${PRODUCT_TYPE.CREDIT}-${[ACTION_TYPE.ORDER_V2]}`]: 'Переход из баннера возврата',
};

export const showActionsMap: Partial<Record<ProductActionType, string>> = {
  [`${PRODUCT_TYPE.OSAGO}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Показ|Продолжить расчёт',
  [`${PRODUCT_TYPE.OSAGO}-${[ACTION_TYPE.ORDER_V2]}`]: 'Показ|Продлить полис',
  [`${PRODUCT_TYPE.MORTGAGE}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Показ|Продолжить расчёт',
  [`${PRODUCT_TYPE.MORTGAGE}-${[ACTION_TYPE.ORDER_V2]}`]: 'Показ|Продлить полис',
  [`${PRODUCT_TYPE.CREDIT}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Показ баннера возврата',
  [`${PRODUCT_TYPE.CREDIT}-${[ACTION_TYPE.ORDER_V2]}`]: 'Показ баннера возврата',
};

export const eventCategoryMap: Partial<Record<ProductActionType, string>> = {
  [`${PRODUCT_TYPE.OSAGO}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'ОСАГО',
  [`${PRODUCT_TYPE.OSAGO}-${[ACTION_TYPE.ORDER_V2]}`]: 'ОСАГО',
  [`${PRODUCT_TYPE.MORTGAGE}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Страхование ипотеки',
  [`${PRODUCT_TYPE.MORTGAGE}-${[ACTION_TYPE.ORDER_V2]}`]: 'Страхование ипотеки',
  [`${PRODUCT_TYPE.CREDIT}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Подбор кредита',
  [`${PRODUCT_TYPE.CREDIT}-${[ACTION_TYPE.ORDER_V2]}`]: 'Подбор кредита',
};

export const eventLabelMap: Partial<Record<ProductActionType, string>> = {
  [`${PRODUCT_TYPE.OSAGO}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Ваши действия',
  [`${PRODUCT_TYPE.OSAGO}-${[ACTION_TYPE.ORDER_V2]}`]: 'Ваши действия',
  [`${PRODUCT_TYPE.MORTGAGE}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Ваши действия',
  [`${PRODUCT_TYPE.MORTGAGE}-${[ACTION_TYPE.ORDER_V2]}`]: 'Ваши действия',
  [`${PRODUCT_TYPE.CREDIT}-${[[ACTION_TYPE.SEARCH_RESULTS_V2]]}`]: 'Этап воронки - Продолжить оформление',
  [`${PRODUCT_TYPE.CREDIT}-${[ACTION_TYPE.ORDER_V2]}`]: 'Этап воронки - Продолжить оформление',
};
