import { FC } from 'react';

import { Card, Space } from '@sravni/react-design-system';
import { useIsPhone, useStyles } from '@sravni/react-utils';

import styles from './SecurityCard.module.scss';

export const SecurityCard: FC = (props) => {
  const { children } = props;

  const isPhone = useIsPhone();
  const cx = useStyles(styles);

  return (
    <Card size={isPhone ? 16 : 24} variant="secondary" color="dark" className={cx('card', 'h-br-16')}>
      <Space direction="column" size={16}>
        {children}
      </Space>
    </Card>
  );
};
