import React from 'react';

export const CreditCard = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 21H5C3.343 21 2 19.657 2 18V8C2 6.343 3.343 5 5 5H21C22.657 5 24 6.343 24 8V13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M1 9H24" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M6.5 16H11.5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
      <path
        d="M22 25C25.3137 25 28 22.3137 28 19C28 15.6863 25.3137 13 22 13C18.6863 13 16 15.6863 16 19C16 22.3137 18.6863 25 22 25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M24.5 19H23C22.4477 19 22 18.5523 22 18L22 16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BanksReviews = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H23C24.105 3 25 3.895 25 5V19C25 20.105 24.105 21 23 21H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21L16.918 25L10 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 9H20.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M7.5 13L17.5 13" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};

export const InsuranceReviews = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H23C24.105 3 25 3.895 25 5V19C25 20.105 24.105 21 23 21H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21L16.918 25L10 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 9H20.5" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
      <path d="M7.5 13L17.5 13" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
    </svg>
  );
};

export const QNA = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 21H5C3.895 21 3 20.105 3 19L3 5C3 3.895 3.895 3 5 3H23C24.105 3 25 3.895 25 5V19C25 20.105 24.105 21 23 21H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.083 21L17 25L10 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14V13.6774C14 12.6219 14.7572 12.0492 15.5168 11.6098C16.2572 11.1787 17 10.6184 17 9.58455C17 8.15685 15.6572 7 14 7C12.3428 7 11 8.15685 11 9.58455"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9968 16C13.4448 16 12.9968 16.448 13 17C13 17.552 13.448 18 14 18C14.552 18 15 17.552 15 17C15 16.448 14.552 16 13.9968 16Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Alfacapital = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M16.874 19.1628C16.874 20.2458 15.9911 21.1249 14.9048 21.1249C13.8203 21.1249 12.9392 20.2458 12.9392 19.1628C12.9392 18.0779 13.8203 17.1994 14.9048 17.1994C15.9911 17.1994 16.874 18.0779 16.874 19.1628Z"
          fill="#CD202C"
        />
        <path
          d="M9.9889 19.1627C9.9889 19.9754 9.32714 20.6346 8.51319 20.6346C7.698 20.6346 7.03809 19.9754 7.03809 19.1627C7.03809 18.3493 7.698 17.6902 8.51319 17.6902C9.32714 17.6902 9.9889 18.3493 9.9889 19.1627Z"
          fill="#CD202C"
        />
        <path
          d="M12.9297 14.0633C12.9297 14.8754 12.2704 15.5346 11.454 15.5346C10.6406 15.5346 9.97949 14.8754 9.97949 14.0633C9.97949 13.2487 10.6406 12.5908 11.454 12.5908C12.2704 12.5908 12.9297 13.2487 12.9297 14.0633Z"
          fill="#CD202C"
        />
        <path
          d="M15.8894 8.96163C15.8894 9.77497 15.2301 10.4335 14.4137 10.4335C13.5997 10.4335 12.9392 9.77497 12.9392 8.96163C12.9392 8.1489 13.5997 7.48975 14.4137 7.48975C15.2301 7.48975 15.8894 8.1489 15.8894 8.96163Z"
          fill="#CD202C"
        />
        <path
          d="M4.08729 19.1628C4.08729 19.704 3.64673 20.1438 3.10266 20.1438C2.55982 20.1438 2.11987 19.704 2.11987 19.1628C2.11987 18.6197 2.55982 18.1811 3.10266 18.1811C3.64673 18.1811 4.08729 18.6197 4.08729 19.1628Z"
          fill="#CD202C"
        />
        <path
          d="M7.02882 14.0633C7.02882 14.6039 6.58765 15.0438 6.04481 15.0438C5.50197 15.0438 5.06079 14.6039 5.06079 14.0633C5.06079 13.5203 5.50197 13.0804 6.04481 13.0804C6.58765 13.0804 7.02882 13.5203 7.02882 14.0633Z"
          fill="#CD202C"
        />
        <path
          d="M9.98903 8.96151C9.98903 9.50395 9.54786 9.94318 9.00378 9.94318C8.46217 9.94318 8.021 9.50395 8.021 8.96151C8.021 8.4197 8.46217 7.98108 9.00378 7.98108C9.54786 7.98108 9.98903 8.4197 9.98903 8.96151Z"
          fill="#CD202C"
        />
        <path
          d="M19.8244 14.0635C19.8244 15.1471 18.9445 16.0256 17.8569 16.0256C16.7713 16.0256 15.8901 15.1471 15.8901 14.0635C15.8901 12.9786 16.7713 12.0989 17.8569 12.0989C18.9445 12.0989 19.8244 12.9786 19.8244 14.0635Z"
          fill="#CD202C"
        />
        <path
          d="M22.7755 8.96149C22.7755 10.0464 21.895 10.9254 20.8069 10.9254C19.7236 10.9254 18.8425 10.0464 18.8425 8.96149C18.8419 7.87908 19.7236 7 20.8069 7C21.895 7 22.7755 7.87908 22.7755 8.96149Z"
          fill="#CD202C"
        />
        <path
          d="M25.717 14.0635C25.717 15.1471 24.8365 16.0256 23.7509 16.0256C22.6652 16.0256 21.7834 15.1471 21.7834 14.0635C21.7834 12.9786 22.6652 12.0989 23.7509 12.0989C24.8365 12.0989 25.717 12.9786 25.717 14.0635Z"
          fill="#CD202C"
        />
        <path
          d="M28.6755 19.1628C28.6755 20.2458 27.7944 21.1249 26.7087 21.1249C25.6231 21.1249 24.7419 20.2458 24.7419 19.1628C24.7419 18.0779 25.6231 17.1994 26.7087 17.1994C27.7944 17.1994 28.6755 18.0779 28.6755 19.1628Z"
          fill="#CD202C"
        />
      </g>
    </svg>
  );
};

export const Pik = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19.3991V9.80005H8.36407V19.3991H5.95028V12.1806H2.41379V19.3991H0ZM15.4932 13.8445L11.6311 19.3991H9.48677V9.80005H11.9006V15.3547L15.7626 9.80005H17.907V19.3991H15.4932V13.8445ZM21.4435 15.5339V19.3991H19.0297V9.80005H21.4435V13.7805L24.3288 9.80005H27.1468L23.7113 14.4588L28 19.3991H24.7554L21.4435 15.5339Z"
        fill="#FC4C02"
      />
    </svg>
  );
};

export const Acquiring = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0, 0, 20, 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.43657 7.50008 L4.89657 7.50008 C4.43657 7.50008 4.06323 7.12675 4.06323 6.66675 L4.06323 5.00008 C4.06323 4.54008 4.43657 4.16675 4.89657 4.16675 L8.43657 4.16675 C8.89657 4.16675 9.2699 4.54008 9.2699 5.00008 L9.2699 6.66675 C9.2699 7.12675 8.89657 7.50008 8.43657 7.50008 z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M14.1666 5.27173 C14.7766 5.88173 14.7766 6.87089 14.1666 7.48173"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M16.745 4.16675 C17.965 5.38675 17.965 7.36592 16.745 8.58591"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M15 18.3333 L7.49996 18.3333 C7.03996 18.3333 6.66663 17.96 6.66663 17.5 L6.66663 13.3333 C6.66663 12.8733 7.03996 12.5 7.49996 12.5 L15 12.5 C15.46 12.5 15.8333 12.8733 15.8333 13.3333 L15.8333 17.5 C15.8333 17.96 15.46 18.3333 15 18.3333 z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M11.6666 12.5001 L11.6666 3.33341 C11.6666 2.41258 10.9208 1.66675 9.99996 1.66675 L3.33329 1.66675 C2.41246 1.66675 1.66663 2.41258 1.66663 3.33341 L1.66663 13.3334 C1.66663 14.2542 2.41246 15.0001 3.33329 15.0001 L6.66663 15.0001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M6.66663 15 L15.8333 15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M4.16664 9.9917 C4.16414 9.9917 4.16248 9.99337 4.16248 9.99587 C4.16248 9.99837 4.16414 10 4.16664 10 C4.16914 10 4.17081 9.99837 4.17081 9.99587 C4.17081 9.99337 4.16914 9.9917 4.16664 9.9917 z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M6.66664 10 C6.66414 10 6.66248 10.0017 6.66248 10.0042 C6.66248 10.0067 6.66414 10.0083 6.66664 10.0083 C6.66914 10.0083 6.67081 10.0067 6.67081 10.0042 C6.67081 10.0017 6.66914 10 6.66664 10 z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M9.17079 9.99585 C9.16829 9.99585 9.16663 9.99752 9.16663 10 C9.16663 10.0025 9.16829 10.0042 9.17079 10.0042 C9.17329 10.0042 9.17496 10.0025 9.17496 10 C9.17496 9.99752 9.17329 9.99585 9.17079 9.99585 z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  );
};

export const CoinRub = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0, 0, 20, 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2957 14.2391C18.2349 11.3516 18.2349 6.71914 15.2957 3.83164C12.3566 0.944141 7.6424 0.944141 4.70406 3.83164C1.76573 6.71914 1.7649 11.3516 4.70406 14.2391"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.41699 17.5H16.7503H3.41699V17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.833 11.6665H7.08301"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49884 13.3335V5.8335H11.1463C12.1238 5.8335 12.9163 6.626 12.9163 7.6035C12.9163 8.581 12.1238 9.3735 11.1463 9.3735H7.08301"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Card = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M19.1667 13V8.33333C19.1667 7.04417 18.1225 6 16.8333 6H6.33333C5.04417 6 4 7.04417 4 8.33333V14.1667C4 15.4558 5.04417 16.5 6.33333 16.5H9.83333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 23.5H12.1667C10.8775 23.5 9.83337 22.4558 9.83337 21.1667V15.3333C9.83337 14.0442 10.8775 13 12.1667 13H22.6667C23.9559 13 25 14.0442 25 15.3333V21.1667C25 22.4558 23.9559 23.5 22.6667 23.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19.1667 9.5H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.83337 16.5H25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const Stoke = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    width="20"
    height="20"
    viewBox="0, 0, 20, 20"
    style={{ fill: 'none' }}
    version="1.1"
  >
    <path
      d="M13.3334 7.49992 L13.3334 5.83325 L11.6667 5.83325"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{ stroke: '#171D23' }}
    />
    <path
      d="M4.81504 2.5 L15.185 2.5 C16.0034 2.5 16.6667 3.24583 16.6667 4.16667 L16.6667 15.8333 C16.6667 16.7542 16.0034 17.5 15.185 17.5 L4.81504 17.5 C3.99671 17.5 3.33337 16.7542 3.33337 15.8333 L3.33337 4.16667 C3.33337 3.24583 3.99671 2.5 4.81504 2.5 L4.81504 2.5 z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{ stroke: '#171D23' }}
    />
    <path
      d="M6.66675 11.25 L13.3334 11.25"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{ stroke: '#171D23' }}
    />
    <path
      d="M6.66675 14.1667 L10.0001 14.1667"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{ stroke: '#171D23' }}
    />
    <path
      d="M13.3334 5.83325 L10.8334 8.33325 L8.57175 6.66659 L6.66675 8.33325"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{ stroke: '#171D23' }}
    />
  </svg>
);

export const Ved = ({ className }: { className?: string }) => {
  return (
    <svg className={className} data-qa="Handshake" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.774 13.778H3.29a1.333 1.333 0 0 0 1.333-1.333V7.11A1.333 1.333 0 0 0 3.29 5.778H2.02m15.871 0h-1.268a1.333 1.333 0 0 0-1.333 1.333v5.334a1.333 1.333 0 0 0 1.333 1.333h1.517"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.31 12.675h-.86c-.285 0-.568.06-.828.18L9.413 14.77m-.813-8.075a1.988 1.988 0 0 0-1.675-.164l-2.303.829m10.828-.897-2.876-1.266a2.001 2.001 0 0 0-1.893.152l-2.61 1.69A1.506 1.506 0 0 0 9.527 9.67l1.556-.726"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.321 3.664A9 9 0 1 1 3.593 16.392 9 9 0 0 1 16.322 3.664"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m11.083 8.942.79.717a2 2 0 0 1 .008 2.957l-2.017 1.846c-.761.698-1.929.7-2.693.007l-1.386-1.257a1.997 1.997 0 0 0-1.185-.5"
      />
    </svg>
  );
};
