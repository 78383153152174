import { parseISO } from 'date-fns';

import { IUserAction } from '../../../interfaces/recentAction';

/** Sort action by date */
export const sortByDate = (data: IUserAction[]) => {
  return data.sort((a, b) => {
    return parseISO(a.date) > parseISO(b.date) ? -1 : 1;
  });
};

/** Add to number thousand separator */
export const addThousandSpaces = (value: number) => {
  return String(value).replace(/(?<=[0-9])(?=(?:[0-9]{3})+(?![0-9]))/g, ' ');
};
