import React from 'react';

export const Up = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M15 11.6665L10 6.6665L5 11.6665L15 11.6665Z" fill="#0DD149" />
    </svg>
  );
};

export const Down = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5 8.3335L10 13.3335L15 8.3335H5Z" fill="#C11D1D" />
    </svg>
  );
};
