import classNames from 'classnames';
import { PreloaderPolygon, TYPES } from 'components/Preloader';
import React from 'react';

import { useIsMobile } from '@sravni/react-utils';

import styles from './card.module.scss';

interface ICardPreloaderProps {
  type?: 'mobile-slider';
}

export const Preloader: React.FC<ICardPreloaderProps> = React.memo(({ type }) => {
  const isMobile = useIsMobile();

  return (
    <div className={type === 'mobile-slider' ? styles['content--mobile-slider'] : styles['content']}>
      {Array(4)
        .fill(null)
        .map((_, i) => (
          <div
            className={classNames(
              styles.block,
              type === 'mobile-slider' && styles['block--mobile-slider'],
              styles.preloader,
              isMobile ? styles['preloader--mobile'] : styles['preloader--desktop'],
            )}
            key={`qna-preloader-block-${i}`}
          >
            <div className={classNames(styles['preloader__img-container'])}>
              <PreloaderPolygon style={{ width: '120px', height: '20px' }} type={TYPES.LIGHT} />
              {!isMobile && <PreloaderPolygon style={{ width: '80px', height: '20px' }} type={TYPES.LIGHT} />}
              <PreloaderPolygon style={{ width: '28px', height: '20px' }} type={TYPES.LIGHT} />
            </div>
            <div className={styles['preloader__body']}>
              {isMobile ? (
                <>
                  <PreloaderPolygon />
                  <PreloaderPolygon />
                  <PreloaderPolygon style={{ width: '120px' }} />
                </>
              ) : (
                <>
                  <PreloaderPolygon />
                  <PreloaderPolygon />
                  <PreloaderPolygon style={{ width: '200px' }} />
                  <div className={styles['preloader__body__bottom']}>
                    <PreloaderPolygon style={{ width: '60px' }} />
                    <PreloaderPolygon style={{ width: '40px' }} />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
    </div>
  );
});
