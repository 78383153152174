import React from 'react';

import { Layout, Space } from '@sravni/react-design-system';
import { useStyles } from '@sravni/react-utils';
import { pluralizeByUnit } from '@sravni/utils/lib/pluralize';

import { useDepositsTop } from '../../hooks/deposits';
import { useMortgages } from '../../hooks/mortgages';
import { DepositsTop } from '../../interfaces/deposits';
import { Mortgages } from '../../interfaces/mortgages';
import { LayoutWrapper } from '../LayoutWrapper';

import { MortgageCard } from './components/MortgageCard';
import { PropositionCard } from './components/PropositionCard';
import { PROPOSITION_LINK, PROPOSITION_TYPE } from './constants/proposition';
import styles from './PropositionBlock.module.scss';
import { IPropositions } from './types/propositionBlock';

/** Pluralize period helper function */
const makePeriodName = (period: number): string => {
  if (period < 12) {
    const pluralizePeriod = pluralizeByUnit(period, 'month');

    return `На ${period} ${pluralizePeriod}`;
  }

  const yearPeriod = period / 12;
  const pluralizePeriod = pluralizeByUnit(yearPeriod, 'year');

  return `На ${yearPeriod} ${pluralizePeriod}`;
};

/** Helper function prepares data for the Card */
const prepareDepositsTopData = (depositsTopData: DepositsTop): IPropositions => {
  return depositsTopData.map(({ period, rate, depositHref }) => {
    return { title: makePeriodName(period), rateTitle: rate ? `${rate}%` : null, href: depositHref };
  });
};

const prepareMortgageTopData = (mortgageTopData: Mortgages): IPropositions => {
  return mortgageTopData?.map(({ programName, rate, url }) => {
    return { title: programName, rateTitle: rate ? `${rate}%` : null, href: url };
  });
};

export const PropositionBlock: React.FC = () => {
  const depositsTop = useDepositsTop();
  const mortgages = useMortgages();
  const cx = useStyles(styles);

  return (
    <LayoutWrapper topBorders={false} bottomBorders={false}>
      <Layout className={cx('h-pt-40', 'h-pb-40')}>
        <Space size={24} className={cx('container')}>
          <PropositionCard
            type={PROPOSITION_TYPE.DEPOSITS}
            title="Выгодные вклады"
            subTittle="Проверили ставки в 256 банках"
            link={PROPOSITION_LINK.DEPOSITS}
            data={prepareDepositsTopData(depositsTop)}
          />
          <Space size={24} className={cx('blocks')}>
            <PropositionCard
              type={PROPOSITION_TYPE.MORTGAGE}
              title="Ставки по ипотеке"
              subTittle="Проверили ставки в 59 банках"
              link={PROPOSITION_LINK.MORTGAGE}
              data={prepareMortgageTopData(mortgages)}
            />
            <MortgageCard />
          </Space>
        </Space>
      </Layout>
    </LayoutWrapper>
  );
};
