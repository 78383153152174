import React from 'react';

import { IMaterialItem, IRubricItem } from '../../interfaces/news';

import MaterialItem from './item';
import styles from './materials.module.scss';

interface IBlocksLineProps {
  items: IMaterialItem[];
  dictionary: IRubricItem[];
  isVisible: boolean;
}

export const BlocksLine: React.FC<IBlocksLineProps> = React.memo(({ items, dictionary, isVisible }) => {
  const itemsList = items.sort((a, b) => {
    // @ts-ignore
    return new Date(b.pubDate) - new Date(a.pubDate);
  });

  return (
    <div {...(isVisible ? { className: styles.content } : { className: styles['materials--hide'] })}>
      {itemsList.map((item) => (
        <MaterialItem item={item} dictionary={dictionary} key={`tab-content-${item.id}`} />
      ))}
    </div>
  );
});
