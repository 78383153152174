import { isAfter, startOfToday, startOfDay } from 'date-fns';
import React, { ReactElement } from 'react';

import { Badge } from '@sravni/react-design-system';
import { Deposit, House, HouseSafety, Plane, PrizeCup, WalletBag } from '@sravni/react-icons';

import styles from '../components/PromoBlock/promo-block.module.scss';

export enum TYPES {
  CREDIT = 'credit',
  OSAGO = 'osago',
  VKLADY = 'vklady',
  MFO = 'mfo',
  MORTGAGE = 'mortgage',
  MORTGAGE_INSURANCE = 'mortgageInsurance',
  VZR = 'vzr',
  LOANS = 'loans',
  CREDITS_RATING = 'creditsRating',
  SPORT_INSURANCE = 'sportInsurance',
  EDUCATION = 'education',
  KASKO = 'kasko',
}

enum COLUMNS {
  COLUMN1 = 'column1',
  COLUMN2 = 'column2',
  COLUMN3 = 'column3',
}

export interface IBlock {
  key: TYPES;
  pageUrl: string;
  title: string | JSX.Element;
  eventCategory?: string;
  picture?: JSX.Element[];
  pictureForDarkTheme?: JSX.Element[];
  titleMobile?: string;
  label?: JSX.Element;
  text: JSX.Element;
  buttonText?: string;
  buttonTextMobile?: string;
  isForm?: boolean;
  column?: number;
  row?: number;
  subtitle?: string | JSX.Element;
  badge?: ReactElement;
  mobileBadge?: ReactElement;
  icon?: ReactElement;
  customStyle?: React.CSSProperties;
  isExternalLink?: boolean;
  partnerPageUrl?: string;
  eventLabel?: string;
  eventAction?: string;
}

type BannerRow = {
  row: IBlock[];
};

type BannerColumn = {
  rows: BannerRow[];
};

type TBannerDataNew = {
  [key in COLUMNS]: BannerColumn;
};

export type TBannersData = {
  [key in TYPES]: IBlock;
};

const endOfVzrPromo = new Date(2024, 11, 22);
const endOfLoansPromo = new Date(2024, 10, 14);

export const BANNERS_DATA_NEW: Partial<TBannerDataNew> = {
  [COLUMNS.COLUMN1]: {
    rows: [
      {
        row: [
          {
            key: TYPES.OSAGO,
            customStyle: { flexBasis: '66.7%' },
            title: 'ОСАГО',
            eventCategory: 'ОСАГО',
            subtitle: 'Поможем сравнить цены и сэкономить до\u00A05\u00A0500 ₽',
            //badge: <Badge color="blue" variant="primary" text="разыгрываем авто и 1 миллион рублей" />,
            //mobileBadge: <Badge color="blue" variant="primary" text="розыгрыш авто" />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/osago/',
            picture: [
              <source
                key={`source-promo-osago-desktop-webp`}
                srcSet={require(`../public/images/promoBlocks/osago-desktop.png?webp`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/webp"
              />,
              <source
                key={`source-promo-osago-desktop-png`}
                srcSet={require(`../public/images/promoBlocks/osago-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/png"
              />,
              <img
                key={`img-promo-osago-mobile`}
                src={require(`../public/images/promoBlocks/osago-mobile.png`)}
                alt=""
              />,
            ],
            pictureForDarkTheme: [
              <source
                key={`source-promo-osago-desktop-webp`}
                srcSet={require(`../public/images/promoBlocks/osago-desktop.png?webp`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/webp"
              />,
              <source
                key={`source-promo-osago-desktop-png`}
                srcSet={require(`../public/images/promoBlocks/osago-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/png"
              />,
              <img
                key={`img-promo-osago-mobile`}
                src={require(`../public/images/promoBlocks/osago-mobile.png`)}
                alt=""
              />,
            ],
            label: <Badge color={'orange'} variant={'secondary'} text={'Сервис №1 в России'} />,
            buttonText: 'Купить',
            buttonTextMobile: 'Купить',
          },
          // {
          //   key: TYPES.KASKO,
          //   customStyle: { flexBasis: '33.3%' },
          //   title: 'КАСКО',
          //   eventCategory: 'КАСКО',
          //   subtitle: 'Сравните цены за 1 минуту',
          //   icon: <CarAccident />,
          //   text: (
          //     <span>
          //       Поможем сравнить цены
          //       <br />
          //       и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
          //     </span>
          //   ),
          //   pageUrl: '/kasko/',
          // },
        ],
      },
      {
        row: [
          {
            key: TYPES.CREDIT,
            title: 'Подбор кредита',
            eventCategory: 'Подбор кредита',
            subtitle: 'Подберем банки, готовые выдать кредит на подходящих вам условиях',
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/kredity/onlajn-zayavka-na-kredit/',
            picture: [
              <source
                key={`source-promo-loans-desktop-desktop-webp`}
                srcSet={require(`public/images/promoBlocks/loans-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/webp"
              />,
              <source
                key={`source-promo-loans-desktop-desktop-png`}
                srcSet={require(`public/images/promoBlocks/loans-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/png"
              />,
              <img key={`img-promo-loans-mobile`} src={require(`public/images/promoBlocks/loans-mobile.png`)} alt="" />,
            ],
            pictureForDarkTheme: [
              <source
                key={`source-promo-loans-desktop-desktop-webp`}
                srcSet={require(`public/images/promoBlocks/loans-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/webp"
              />,
              <source
                key={`source-promo-loans-desktop-desktop-png`}
                srcSet={require(`public/images/promoBlocks/loans-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/png"
              />,
              <img key={`img-promo-loans-mobile`} src={require(`public/images/promoBlocks/loans-mobile.png`)} alt="" />,
            ],
          },
        ],
      },
    ],
  },
  [COLUMNS.COLUMN2]: {
    rows: [
      {
        row: [
          {
            key: TYPES.LOANS,
            title: 'Займы',
            eventCategory: 'Займы',
            subtitle: 'На карту за 10 минут',
            badge: !isAfter(startOfToday(), startOfDay(endOfLoansPromo)) ? (
              <Badge color="violet" variant="primary" text="РОЗЫГРЫШ" />
            ) : (
              <Badge color="violet" variant="primary" text="от 0%" />
            ),
            icon: <WalletBag />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/zaimy/',
          },
          {
            key: TYPES.MORTGAGE_INSURANCE,
            title: (
              <>
                Страхование
                <br />
                ипотеки
              </>
            ),
            eventCategory: 'Страхование ипотеки',
            subtitle: <>Страховка для банка</>,
            icon: <HouseSafety />,
            badge: <Badge color="violet" variant="primary" text="приз 500 000 ₽" />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/strahovanie-ipoteki/',
          },
        ],
      },
      {
        row: [
          {
            key: TYPES.CREDITS_RATING,
            title: 'Кредитный рейтинг',
            eventCategory: 'Кредитный рейтинг',
            subtitle: 'Проверьте кредитную историю на отсутствие просрочек и ошибок',
            badge: <Badge color="violet" variant="primary" text="Бесплатно" />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/kredity/scoring/',
            picture: [
              <source
                key={`source-promo-credits-rate-desktop-webp`}
                srcSet={require(`../public/images/promoBlocks/credits-rate-desktop.png?webp`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/webp"
              />,
              <source
                key={`source-promo-credits-rate-desktop-png`}
                srcSet={require(`../public/images/promoBlocks/credits-rate-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/png"
              />,
              <img
                key={`img-promo-credits-rate-mobile`}
                src={require(`../public/images/promoBlocks/credits-rate-mobile.png`)}
                alt=""
              />,
            ],
            pictureForDarkTheme: [
              <source
                key={`source-promo-credits-rate-desktop-webp`}
                srcSet={require(`../public/images/promoBlocks/credits-rate-desktop.png?webp`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/webp"
              />,
              <source
                key={`source-promo-credits-rate-desktop-png`}
                srcSet={require(`../public/images/promoBlocks/credits-rate-desktop.png`)}
                media={`(min-width: ${styles.breakpointDesktop})`}
                type="image/png"
              />,
              <img
                key={`img-promo-credits-rate-mobile`}
                src={require(`../public/images/promoBlocks/credits-rate-mobile.png`)}
                alt=""
              />,
            ],
          },
        ],
      },
    ],
  },
  [COLUMNS.COLUMN3]: {
    rows: [
      {
        row: [
          {
            key: TYPES.VKLADY,
            title: 'Вклады',
            eventCategory: 'Вклады',
            subtitle: 'Найдите лучшую ставку',
            icon: <Deposit />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/vklady/',
          },
          {
            key: TYPES.MORTGAGE,
            title: 'Ипотека',
            eventCategory: 'Ипотека',
            subtitle: 'Подберите лучшее предложение',
            icon: <House />,
            isExternalLink: true,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/ipoteka/',
            eventLabel: 'Онлайн-заявка на ипотеку|Подберите лучшее предложение|Переход из плиток',
            eventAction: 'Платный клик',
          },
        ],
      },
      {
        row: [
          {
            key: TYPES.VZR,
            title: 'Туристическая страховка',
            eventCategory: 'ВЗР',
            icon: <Plane />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            badge: !isAfter(startOfToday(), startOfDay(endOfVzrPromo)) ? (
              <Badge color="violet" variant="primary" text="Приз 100 000 ₽" />
            ) : null,
            pageUrl: '/vzr/',
          },
          {
            key: TYPES.SPORT_INSURANCE,
            title: 'Страхование спортсменов',
            eventCategory: 'НС',
            icon: <PrizeCup />,
            text: (
              <span>
                Поможем сравнить цены
                <br />
                и&nbsp;сэкономить от&nbsp;800 до&nbsp;5&nbsp;500&nbsp;₽
              </span>
            ),
            pageUrl: '/strahovanie-ns/',
          },
        ],
      },
    ],
  },
};
