import React from 'react';

import { Services } from '@sravni/react-icons';
import { useBoolean, useIsMobile } from '@sravni/react-utils';

import { SERVICE_MENU, ServiceMenu } from '../../../ServiceMenu/index_variantB';
import { IProductData, TYPES } from '../../types/productBlock';
import { Card } from '../Card';
import { CardContent } from '../CardContent';

import styles from './ServiceMenuCard.module.scss';

const item: IProductData = {
  key: TYPES.SERVICE_MENU,
  customStyle: { minWidth: '80px' },
  title: (
    <>
      Все <br />
      услуги
    </>
  ),
  eventAction: 'Открытие попапа',
  eventCategory: 'Все услуги',
  subtitle: '',
  icon: <Services />,
  pageUrl: SERVICE_MENU,
  cardColor: 'blue',
};

export const ServiceMenuCard = () => {
  const [visibleServiceMenu, setVisibleServiceMenu] = useBoolean(false);

  const isMobile = useIsMobile();

  return (
    <>
      <Card item={item} className={styles.serviceMenu} onClick={setVisibleServiceMenu.on}>
        <CardContent item={item} />
      </Card>
      <ServiceMenu visible={visibleServiceMenu} isMobile={isMobile} onClose={setVisibleServiceMenu.off} />
    </>
  );
};
