import getConfig from 'next/config';
import urlJoin from 'url-join';

import { IUserAction } from '../interfaces/recentAction';
import client from '../utils/client';

export const getRecentUserActions = async () => {
  const url = urlJoin(getConfig().publicRuntimeConfig.apiPath, `/recentActions`);

  const { data } = await client.get<IUserAction[]>(url);

  return data;
};

export const deleteRecentUserAction = async (actionId: string) => {
  const url = urlJoin(getConfig().publicRuntimeConfig.apiPath, `/deleteAction/${actionId}`);

  const resp = await client.delete<IUserAction>(url);

  return resp;
};
