import React from 'react';

import { getRecentUserActions } from '../../../services/recentAction';
import { IRecentActions } from '../types/recentActionCard';
import { prepareActionCards } from '../utils/actionData';
import { sortByDate } from '../utils/changeData';

interface IRecentActionsBlock {
  loading: boolean;
  recentActions: IRecentActions;
  getRecentActions?: () => void;
}

/**
 *  React hook uses to get and prepare the recent user action cards
 * @returns object with recent user actions
 */
export const useRecentActions = (): IRecentActionsBlock => {
  const [recentActions, setRecentActions] = React.useState<IRecentActions>({
    orders: [],
    calculations: [],
    primaryAction: {},
  });
  const [loading, setLoading] = React.useState<boolean>(true);

  const getRecentActions = React.useCallback(async () => {
    try {
      const actions = await getRecentUserActions();
      const sortedActions = sortByDate(actions);
      setRecentActions(prepareActionCards(sortedActions));
    } catch (e) {
      console.error('Failed to fetch recent user actions');
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getRecentActions();
  }, [getRecentActions]);

  return { loading, recentActions, getRecentActions };
};
