import { format } from 'date-fns/format';

const dateNow = format(new Date(), 'yyyy-MM-dd');

export const CURRENCIES_DATA = [
  {
    code: 'USD',
    link: '/valjuty/cb-rf/usd/',
  },
  {
    code: 'EUR',
    link: '/valjuty/cb-rf/eur/',
  },
];

export const CURRENCIES_DATA_B_TEST = [
  {
    code: 'USD',
    link: '/valjuty/cb-rf/usd/',
  },
  {
    code: 'EUR',
    link: '/valjuty/cb-rf/eur/',
  },
  {
    code: 'CNY',
    link: '/valjuty/cb-rf/cny/',
  },
];

export const CUR_FETCH_PARAMS = {
  dateTo: dateNow,
  lastTakeCount: 2,
  isoCodes: ['USD', 'EUR', 'CNY'],
};
