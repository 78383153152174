import classNames from 'classnames';
import React from 'react';

import { Icon, Space, Typography } from '@sravni/react-design-system';
import { Message, ThumbUp } from '@sravni/react-icons';
import { OptimizedImg } from '@sravni/ugc/lib/optimizedImgV2';

import styles from './card.module.scss';

export type TCompressions = 'xl' | 'l' | 'm' | 's' | 'xs' | 't';

interface ICardProps {
  item: {
    imgSrc?: string;
    imgCompressions?: [TCompressions, TCompressions] | 'auto';
    link: string;
    tags: string[];
    title: {
      __html: string;
    };
    viewsCount: number;
    commentsCount?: number;
    likesCount: number;
    date?: string;
    priorityOnMain?: number;
    handleClick?: () => void;
    rubricAlias?: string;
    rubricName?: string;
  };
  type?: 'mobile-slider';
}

export const Card: React.FC<ICardProps> = React.memo(({ item }) => {
  const {
    link,
    imgSrc,
    imgCompressions,
    rubricName,
    rubricAlias,
    tags,
    title,
    date,
    commentsCount,
    likesCount,
    priorityOnMain,
    handleClick,
  } = item;

  const showImg = true;
  const showPartnerLabel = priorityOnMain > 0 || tags.includes('Партнёрский материал');

  return (
    <Space className={classNames(styles.block, { [styles[`block--small`]]: !showImg })}>
      {showImg ? (
        <a className={styles.imageWrapper} href={link} onClick={handleClick}>
          {imgSrc ? (
            <OptimizedImg compression={imgCompressions} src={imgSrc} alt={''} className={styles.image} />
          ) : null}
        </a>
      ) : null}
      <a className={styles.description} href={link} onClick={handleClick}>
        <div>
          <Space className={styles.descriptionInfo} justify={'space-between'} align={'center'}>
            {date}
            <Space justify={'end'} align={'center'} size={8}>
              {commentsCount > 0 ? (
                <div className={styles.infoBlock}>
                  <Icon icon={<Message />} size={16} />
                  <span>{commentsCount}</span>
                </div>
              ) : null}
              {likesCount > 0 ? (
                <div className={styles.infoBlock}>
                  <Icon icon={<ThumbUp />} size={16} />
                  <span>{likesCount}</span>
                </div>
              ) : null}
            </Space>
          </Space>
          <div className={styles.descriptionTitle} dangerouslySetInnerHTML={title} />
        </div>
        <Space className={styles.descriptionInfo} align={'center'} size={12}>
          <Typography.Link className={'h-color-D100'} href={`/novosti/rubrika/${rubricAlias}/`}>
            {rubricName}
          </Typography.Link>
          {showPartnerLabel && <span>Партнерский материал</span>}
        </Space>
      </a>
    </Space>
  );
});
