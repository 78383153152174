import { startOfDay, addDays, subDays, parseISO } from 'date-fns';

import { ACTION_TYPE, IUserAction } from '../../../interfaces/recentAction';
import {
  ICreditCalculationData,
  IMortgageCalculationData,
  IMortgageOrderData,
  IOsagoCalculationData,
  IOsagoOrderData,
} from '../types/actionDataTypes';

/** Helper function uses for filtering relevant actions dates */
export const isRelevantDate = (action: IMortgageOrderData) => {
  const { dateEnd } = action.payload;
  const today = startOfDay(new Date());
  const daysOffsetBefore = 40;
  const daysOffsetAfter = 20;

  // Релевантные даты - за `daysOffsetBefore` дней до окончания полиса и `daysOffsetAfter` после
  if (parseISO(dateEnd) <= addDays(today, daysOffsetBefore) && parseISO(dateEnd) >= subDays(today, daysOffsetAfter)) {
    return true;
  }

  return false;
};

/** Data types validation */
export const isMortgageCalculation = (data: IUserAction): data is IMortgageCalculationData => {
  if (data.actionType === ACTION_TYPE.SEARCH_RESULTS_V2 && 'searchId' in data.meta) {
    return true;
  }

  return false;
};

export const isMortgageOrder = (data: IUserAction): data is IMortgageOrderData => {
  if (data.actionType === ACTION_TYPE.ORDER_V2 && 'externalId' in data.meta && 'dateEnd' in data.payload) {
    return true;
  }

  return false;
};

export const isOsagoCalculation = (data: IUserAction): data is IOsagoCalculationData => {
  if (data.actionType === ACTION_TYPE.SEARCH_RESULTS_V2 && 'searchId' in data.meta) {
    return true;
  }

  return false;
};

export const isOsagoOrder = (data: IUserAction): data is IOsagoOrderData => {
  if (data.actionType === ACTION_TYPE.ORDER_V2 && 'externalId' in data.meta && 'dateEnd' in data.payload) {
    return true;
  }

  return false;
};

export const isCreditCalculation = (data: IUserAction): data is ICreditCalculationData => {
  if (data.actionType === ACTION_TYPE.SEARCH_RESULTS_V2) {
    return true;
  }

  return false;
};

export const isCreditOrder = (data: IUserAction): data is ICreditCalculationData => {
  if (data.actionType === ACTION_TYPE.ORDER_V2) {
    return true;
  }

  return false;
};
