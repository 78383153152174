import { Space, Typography } from '@sravni/react-design-system';
import { BasicСhecksquare, Message, Phone } from '@sravni/react-icons';

import { CardItem } from '../CardItem';
import { SecurityCard } from '../SecurityCard';

export const SecurityRules = () => {
  return (
    <SecurityCard>
      <Space size={12} align="center">
        <img width={36} height={48} src={require('./img.png')} alt="rules" />
        <Typography.Heading level={5}>
          Важные правила
          <br />
          безопасности
        </Typography.Heading>
      </Space>
      <CardItem text="Никому не сообщайте свои персональные данные или код из&nbsp;смс">
        <Phone />
      </CardItem>
      <CardItem text="Сотрудники Сравни и ее представители не запрашивают смс ни при каких условиях">
        <Message />
      </CardItem>
      <CardItem text="При наличии сомнений, позвоните  по официальному номеру на сайте">
        <BasicСhecksquare />
      </CardItem>
    </SecurityCard>
  );
};
