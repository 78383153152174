import { Space, Typography } from '@sravni/react-design-system';
import { useIsPhone } from '@sravni/react-utils';

import { CustomerCare } from '../CustomerCare';
import { PersonalData } from '../PersonalData';
import { SecurityRules } from '../SecurityRules';

import styles from './SecurityBlock.module.scss';

export const SecurityBlock = () => {
  const isPhone = useIsPhone();

  return (
    <Space direction="column" size={isPhone ? 16 : 24}>
      <Typography.Heading level={3}>Заботимся о вашей безопасности</Typography.Heading>
      <Space direction={isPhone ? 'column' : 'row'} size={isPhone ? 16 : 24} wrap>
        <div className={styles.card}>
          <PersonalData />
        </div>
        <div className={styles.card}>
          <SecurityRules />
        </div>
        <div className={styles.card}>
          <CustomerCare />
        </div>
      </Space>
    </Space>
  );
};
