export enum PRODUCT_TYPE {
  MORTGAGE = 'insurance-mortgage',
  OSAGO = 'osago',
  VZR = 'insurance-vzr',
  CREDIT = 'credit-selection',
}

export enum ACTION_TYPE {
  CREDIT_DELETE_V2 = 'credit-delete-v2',
  CREDIT_V2 = 'credit-v2',
  DEAL = 'deal',
  DEAL_DELETE = 'deal-delete',
  DEAL_V2 = 'deal-v2',
  DEAL_V2_DELETE = 'deal-v2-delete',
  DEPOSIT = 'deposit',
  DEPOSIT_DELETE = 'deposit-delete',
  DRAFT = 'draft',
  DRAFT_V2 = 'draft-v2',
  FOREIGN_ORDER = 'foreign-order',
  FOREIGN_ORDER_DELETE = 'foreign-order-delete',
  ORDER = 'order',
  ORDER_CANCELLED_V2 = 'order-cancelled-v2',
  ORDER_V2 = 'order-v2',
  SEARCH_RESULTS = 'search-results',
  SEARCH_RESULTS_V2 = 'search-results-v2',
  AUTO = 'auto',
}

export type ProductType = `${PRODUCT_TYPE}`;
export type ActionType = `${ACTION_TYPE}`;
export type ProductActionType = `${ProductType}-${ActionType}`;
export type UnknownObject = {
  [key: string]: unknown;
};

export interface IUserAction {
  actionId?: string;
  actionType: ActionType;
  productType: ProductType;
  meta: UnknownObject;
  payload: UnknownObject;
  date: string;
}
