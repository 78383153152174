import { ReactElement } from 'react';
import { CardProps } from '@sravni/react-design-system/lib/Card';

export enum TYPES {
  OSAGO = 'osago',
  CREDIT = 'credit',
  MORTGAGE_INSURANCE = 'mortgageInsurance',
  LOANS = 'loans',
  MORTGAGE = 'mortgage',
  DEPOSITS = 'deposits',
  CREDITS_RATING = 'creditsRating',
  VZR = 'vzr',
  SPORT_INSURANCE = 'sportInsurance',
  KASKO = 'kasko',
  CREDITS_CARDS = 'creditsCards',
  EDUCATION = 'education',
  BUSINESS = 'business',
  REFINANCING = 'refinancing',
  SERVICE_MENU = 'serviceMenu',
}

export type IAttribute<T> =
  | T
  | {
      desktop: T;
      mobile: T;
    };

export interface IProductData {
  key: TYPES;
  title: string | JSX.Element;
  subtitle?: IAttribute<string | JSX.Element>;
  icon?: IAttribute<ReactElement>;
  bottomIcon?: IAttribute<ReactElement>;
  cardColor?: CardProps['color'];
  pageUrl: string;
  badge?: ReactElement;
  customStyle?: React.CSSProperties;
  picture?: JSX.Element[];
  pictureCustomStyle?: React.CSSProperties;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
}
